
import React from "react"
import { navigate } from "gatsby"
export default () => 
{
  if (typeof window !== "undefined") 
  {
    navigate(`/compare?home1=M4199380152_NH&home2=M9969254617_UT`)
  }
  return (
      <div> - </div>
  )
}

